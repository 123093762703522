<template>
  <div>
    <div class="test-szondi no-selection">
      <a-modal
        ref="popup"
        :title="$t('pages.test-szondi.title')"
        :cancel-text="$t('buttons.cancel')"
        :ok-text="$t('buttons.confirm')"
        :visible="!testStarted"
        :closable="false"
        @ok="onConfirmDialogue"
        @cancel="onCancelDialogue"
      >
        <h4 class="text-muted text-left">
          {{ $t("test-szondi.noticeLine1") }}
        </h4>
        <h4 class="text-success text-left">
          {{ $t("test-szondi.noticeLine2") }}
        </h4>
        <h4 class="text-danger text-left">
          {{ $t("test-szondi.noticeLine3") }}
        </h4>
        <h4 class="text-muted text-left">
          {{ $t("test-szondi.noticeLine4") }}
        </h4>
        <h4 class="text-muted text-left">
          {{ $t("test-szondi.noticeLine5") }}
        </h4>
      </a-modal>
      <a-modal
        v-model="genderModalVisible"
        :title="$t('ui.PleaseProvideYourGender')"
        :footer="null"
        :closable="false"
        @cancel="onCancelDialogueGender"
      >
        <div style="display: flex; justify-content: center">
          <a-button
            key="man"
            style="margin-right: 10px"
            type="primary"
            @click="genderSelected(false)"
          >
            {{ $t("ui.GenderMale") }}
          </a-button>
          <a-button
            key="woman"
            style="margin-left: 10px"
            type="primary"
            @click="genderSelected(true)"
          >
            {{ $t("ui.GenderFemale") }}
          </a-button>
        </div>
      </a-modal>
      <div v-if="resultsVisible ? false : true" id="data_collection_step">
        <div>
          <h3 v-if="PhotosWhichYouLike" class="green-text">
            {{ $t("test-szondi.titleGood") }}
          </h3>
          <h3 v-else-if="PhotosWhichYouLike ? false : true" class="red-text">
            {{ $t("test-szondi.titleBad") }}
          </h3>

          <transition-group
            id="grid"
            tag="ul"
            name="figure-out-in"
            class="grid"
          >
            <div v-for="item in filteredArray" :key="item.key">
              <transition name="figure">
                <img
                  v-if="item.show"
                  :class="item.class"
                  :src="item.url"
                  @click="cliccer(item)"
                />
              </transition>
            </div>
          </transition-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
const moment = require("moment-timezone");

export default {
  middleware: "uiDefaultState",
  data() {
    return {
      canClick: true,
      testStarted: false,
      resultsVisible: false, // default:false,
      PhotosWhichYouLike: true,

      prevSymb: "–",
      frontData: [],
      backData: [],

      sortedPhotos: [],

      // eslint-disable-next-line no-unused-vars
      loadedMaterialsArr: [],

      dataId: "",
      transactions: " ",
      lastMoment: null,
      data: {},

      isWoman: false,
      genderModalVisible: false,
      forceBack: false,
    };
  },
  computed: {
    testsList() {
      return this.getTestsList;
    },
    ...mapGetters([
      "getTestsList",
      "gender",
      "id",
      // ...
    ]),
    filteredArray() {
      let page = 0;
      // 6times x8, 6times x4
      if (this.frontData.length < 24) {
        page = Math.floor(this.frontData.length / 4);
        return this.loadedMaterialsArr.slice(page * 8, (page + 1) * 8);
      } else {
        if (this.backData.length === 0) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.sortedPhotos = this.loadedMaterialsArr.filter(function (phItem) {
            return phItem.class === "figure";
          });

          for (let i = 0; i < this.sortedPhotos.length; i++) {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.sortedPhotos[i].show = true;
          }
        }

        if (this.backData.length < 12) {
          page = Math.floor(this.backData.length / 2);
          return this.sortedPhotos.slice(page * 4, (page + 1) * 4);
        } else {
          return [];
        }
      }
    },
  },
  async mounted() {
    // this.$nextTick(() => {
    //   this.$loading.start()
    // })

    this.setHeaderFooterVis({
      visibleHeader: false,
      visibleFooter: false,
    });

    const testUrl = "/tests/szondi";
    const testLists = this.testsList;
    const test = testLists.find(function (element) {
      return element.url === testUrl;
    });
    if (!test) {
      this.$router.push("/");
      return;
    }

    this.importAll(test.data.images);

    const timeStart = moment.tz("Europe/Kiev");
    this.lastMoment = timeStart;
    this.dataId = "";

    this.data.datestart = timeStart.format();
    this.data.testtype = "Szondi";
    this.data.transactions = this.transactions;
    this.data.result = {};

    // const show = false;
    // if (!process.server) {
    //   const x = document.getElementById("__replain_widget_iframe");
    //   if (x) {
    //     x.style.display = show ? "unset" : "none";
    //   }
    // }

    // await this.waitFor(2000);
    // if (!process.server) {
    //   const x = document.getElementById("__replain_widget_iframe");
    //   if (x) {
    //     x.style.display = show ? "unset" : "none";
    //   }
    // }
  },
  beforeRouteLeave(to, from, next) {
    if (this.forceBack) {
      next(true);
      return;
    }
    if (!this.resultsVisible && this.testStarted) {
      const modal = this.$confirm();

      modal.update({
        title: this.$t("test.stoptest"),
        content: this.$t("test.stoptestnotice"),
        okText: this.$t("buttons.confirm"),
        cancelText: this.$t("buttons.cancel"),
        onOk() {
          next(true);
          modal.destroy();
        },
        onCancel() {
          next(false);
          modal.destroy();
        },
      });
      return;
    }

    if (to === from) {
      this.$router.push("/");
      // this.$router.go({ path: to, force: true })
      return;
    }
    next(true);
  },
  methods: {
    genderSelected(isWoman) {
      this.isWoman = isWoman;
      this.genderModalVisible = false;
    },
    ...mapMutations({ setHeaderFooterVis: "ui/setHeaderFooterVis" }),
    importAll(items) {
      const imgs = [];
      items.forEach(
        (
          item,
          index // .keys()
        ) =>
          imgs.push({
            url: this.$strapi.storageUrl + item.url,
            id: item.key,
            class: "figure",
            show: true,
            key: "figure" + item.key + index,
          })
      );
      this.loadedMaterialsArr = imgs;
    },
    onConfirmDialogue() {
      this.testStarted = true;
      // console.log(this);
      if (this.id) {
        this.isWoman = this.gender;
      } else {
        this.genderModalVisible = true;
      }
    },
    onCancelDialogueGender() {
      this.forceBack = true;
      this.$router.push("/");
    },
    onCancelDialogue() {
      this.$router.push("/");
    },
    CalculateResult(mCount, pCount) {
      // var arr = isFront ? this.frontData : this.backData;
      // 6: !
      // 5: !
      // 4: !
      // 2-3: symb
      // 0-1:
      const result =
        (pCount < 2 && mCount < 2 ? "0" : "") +
        (pCount >= 2 && mCount >= 2
          ? "±"
          : pCount >= 2
          ? "+"
          : mCount >= 2
          ? "–"
          : "") +
        (pCount >= 4 ? "!".repeat(pCount - 3) : "") +
        (mCount >= 4 ? "!".repeat(mCount - 3) : "");

      return result;
    },

    async fillData() {
      if (this.frontData.length === 0) {
        this.resultedDataContainer = {
          frontMinus: [-1, 0, -6, -1, -3, 0, -4, -1],
          frontPlus: [2, 2, 1, 6, 0, 1, 1, 4],
          frontResult: ["+", "–", "+!!", "±", "+", "–", "+!", "±"],
          backMinus: [-1, 0, -6, -1, -3, 0, -4, -1],
          backPlus: [2, 2, 1, 6, 0, 1, 1, 4],
          backResult: ["+", "–", "+!!", "±", "+", "–", "+!", "±"],
        };
        return;
      }

      const labelsArr = ["h", "s", "e", "hy", "k", "p", "d", "m"];
      this.resultedDataContainer = {
        frontMinus: [],
        frontPlus: [],
        frontResult: [],
        backMinus: [],
        backPlus: [],
        backResult: [],
      };
      for (let i = 0; i < labelsArr.length; i++) {
        const fp = this.frontData.filter(function (x) {
          return x === labelsArr[i] + "+";
        }).length;
        const fm = this.frontData.filter(function (x) {
          return x === labelsArr[i] + "–";
        }).length;
        const bp = this.backData.filter(function (x) {
          return x === labelsArr[i] + "+";
        }).length;
        const bm = this.backData.filter(function (x) {
          return x === labelsArr[i] + "–";
        }).length;

        this.resultedDataContainer.frontMinus.push(-fm);
        this.resultedDataContainer.frontPlus.push(fp);
        this.resultedDataContainer.backMinus.push(-bm);
        this.resultedDataContainer.backPlus.push(bp);

        const fr = this.CalculateResult(fm, fp);
        const br = this.CalculateResult(bm, bp);

        this.resultedDataContainer.frontResult.push(fr);
        this.resultedDataContainer.backResult.push(br);
      }

      const timeEnd = moment.tz("Europe/Kiev");

      this.data.dateend = timeEnd.format();
      this.data.status = "Пройден";
      this.data.result = this.resultedDataContainer;
      this.data.transactions = this.transactions;
      this.data.finished = true;

      this.data.result.isWoman = this.isWoman;

      const response = await this.$store.dispatch(
        "testResults/createTestresult",
        this.data
      );
      this.dataId = response.udid;
    },
    // eslint-disable-next-line require-await
    async waitFor(ms) {
      return new Promise(function (resolve) {
        setTimeout(resolve, ms || 0);
      });
    },
    updateLastMoment() {
      const timeEnd = moment.tz("Europe/Kiev");
      this.lastMoment = timeEnd;
    },
    async cliccer(item) {
      if (!item.show || !this.canClick) {
        return;
      }

      item.show = false;
      this.canClick = false;

      await this.waitFor(300);

      if (this.frontData.length < 24) {
        if ((this.frontData.length + 1) % 4 === 0) {
          this.filteredArray.forEach(function (item) {
            item.show = false;
          });
          await this.waitFor(500);
        } else {
          this.canClick = true;
        }
      } else if (this.backData.length < 12) {
        if ((this.backData.length + 1) % 2 === 0) {
          this.filteredArray.forEach(function (item) {
            item.show = false;
          });
          await this.waitFor(500);
        } else {
          this.canClick = true;
        }
      } else {
        this.canClick = true;
      }

      let symb = "+";
      // eslint-disable-next-line require-atomic-updates
      item.class = "hiddenPhoto";

      if (this.frontData.length < 24) {
        // front
        symb = this.frontData.length % 4 >= 2 ? "–" : "+";
        this.frontData.push(item.id + "" + symb);

        // + + - -   + + - -   + + - -
        if (symb === this.prevSymb) {
          this.PhotosWhichYouLike = !this.PhotosWhichYouLike;
        } // меняем знак
        this.prevSymb = symb;
      } else {
        // back
        this.backData.push(item.id + "" + symb);
      }
      /// ////////////////////
      const timeEnd = moment.tz("Europe/Kiev");
      const duration = moment
        .duration(timeEnd.diff(this.lastMoment))
        .asSeconds();
      this.transactions += duration.toString() + " ";

      // -----------end
      //console.log("this.backData.length");
      //console.log(this.backData.length);
      if (this.backData.length == 12) {
        for (let i = this.sortedPhotos.length - 1; i >= 0; i--) {
          const element = this.sortedPhotos[i];
          if (element.class === "figure") {
            this.backData.push(element.id + "–");
          }
        }

        //console.log(this.backData);
        this.resultsVisible = true;

        await this.fillData();

        const url = "/results/" + this.dataId;
        //console.log(url);
        this.$router.push(url);
      }

      if (!this.canClick) {
        await this.waitFor(1000);
        this.canClick = true;
        this.updateLastMoment();
      } else {
        this.updateLastMoment();
      }
    },
  },
};
</script>

<style scoped>
.green-text {
  margin-left: 2%;
  margin-right: 2%;
  margin-top: 20px;
  margin-bottom: 20px;
  color: green;
  font-weight: bold;
  font-size: 1.5em;
}

.red-text {
  margin-left: 2%;
  margin-right: 2%;
  margin-top: 20px;
  margin-bottom: 20px;
  color: red;
  font-weight: bold;
  font-size: 1.5em;
}

.figure {
  justify-self: center;
}

.figure-enter-active,
.figure-leave-active {
  transition: opacity 1.5s;
}
.figure-enter,
.figure-leave-to {
  opacity: 0;
}

.figure-out-in-enter-active {
  transition: opacity 1s;
}

.figure-out-in-leave-active {
  transition: opacity 0s;
  opacity: 0;
}

.figure-out-in-enter-active {
  transition-delay: 0.7s;
}

.figure-out-in-enter,
.figure-out-in-leave-to {
  opacity: 0;
}

/* Transitions using the page hook */
.page-enter-active,
.page-leave-active {
  transition: all 0.3s ease-out;
}
.page-enter,
.page-leave-active {
  opacity: 0;
  transform-origin: 50% 50%;
}

.text-success {
  color: green;
}

.text-danger {
  color: red;
}

h3 {
  text-align: center;
}

#grid {
  display: grid;
  grid-template-columns: repeat(4, 0.9fr);
  justify-content: center;
  justify-items: center;
  grid-gap: 10px;
  margin-left: 1%;
  margin-right: 1%;
}

#grid div {
  display: grid;
  /* justify-items: center; */
  justify-self: center;
}

.grid img {
  position: relative;
  box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.3);
  border: 1px solid #ccc;
  max-width: 90%;
}

.test-szondi ul {
  padding: 0px;
}
</style>
